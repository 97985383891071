import React from "react"
import { Box, Flex } from '@theme-ui/components'
import Layout from '../components/organisms/Layout'
import Container from '../components/organisms/Container'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// markup
const IndexPage = () => {
    return (
    <Layout>
      <Container sx={{ mt: 2 }}>
        <Flex sx={{ height: '70vh', width: '100vw', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ p: 2, border: '1px solid #CECECE', color: 'primary'}}>Loading...</Box>
        </Flex>
      </Container>
    </Layout>
  )
}

export default IndexPage
